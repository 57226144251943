.login-grid {
    height:100%;
}

.login-page__identity-provider-button {
    margin-bottom: 1em!important;
}

.login-page__language-selector {
    position: absolute;
    right: 1em;
    top: 1em;
}