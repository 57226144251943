/*
 * For tall devices (>= 1050px) sidebar can't be closed, so substract sidebar width from content container width
 */

@media (min-width: 1050px) {
    .ui.visible.wide.left.sidebar~.pusher {
        max-width: none!important;
        transform: none!important;
        margin-left:200px;
    }
    .ui.wide.left.sidebar {
        width: 200px;
    }
}

.app-container {
    height: 100%;
    display:flex;
    flex-direction: column;
}

.app-content {
    overflow-y: auto;
    padding-bottom: 1rem;

    position: relative;
    height: 100%;
    width: 100%;
}