body {
  background: #f0f2f5;
  height:100%;
}
#root {
  height:100%;
}

/*
 *
 * Semantic UI overrides
 *
 */

.ui.selectable.table tbody>tr {
  cursor: pointer;
  user-select: none;
}

/* add white variation to button */
.button.white {
  background-color: rgba(255,255,255,.95);
  color: rgba(0,0,0,.95);
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
}

.button.white:hover {
  background-color: rgba(255,255,255,.75);
  background-image: none;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15), 0 0 0 1px transparent inset,0 0 0 0 rgba(34,36,38,.15) inset;
  color: rgba(0,0,0,.8);
}

/**
 * as of semantic ui css 2.4 the fullscreen modal is not centered anymore, this overrides fix that bug
 */
.ui.fullscreen.modal,
.ui.fullscreen.scrolling.modal {
  left: auto !important;
}


/**
 * Link style button
 */
.ui.button.basic.link, .ui.button.basic.link:hover, .ui.button.basic.link:focus {
  box-shadow: 0 0 !important;
  padding: 0!important;
  margin:0!important;
}

.ui.form .help-text {
  margin-top: -1em;
  color: #8B8C8D;
}

/*
 * Generic
 */
.error-msg {
  color: #cb0000;
}