.color {
    color: rgb(64, 69, 69);
}

.logo-caption {
    text-align: center;
    margin-top:10px;
}

.ui.vertical.menu.menu-sidebar,
.menu-sidebar {
    display: inline-flex;
    flex-direction: column;
    border: 0;
    background: #faf9f9;
}

.menu-sidebar .sub-menu {
    background: rgba(255,255,255,0.48);
}

.menu-sidebar .sub-menu .item {

}




.menu-item-logo {
    background: #ffffff !important;
}

.ui.vertical.menu.menu-sidebar .item {
    box-sizing:border-box;
    border-bottom: none;
    border-right-style: solid;
    border-right-color: transparent;
    border-radius: 0!important;
    border-right-width: 2px;
}

.ui.vertical.menu.menu-sidebar .active.item {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #1B1C1D;
    font-weight: 700;
    color: rgba(0,0,0,.95);
}

.ui.vertical.menu.menu-sidebar .sub-menu .item:not(.active):hover {
    color: rgba(219, 40, 40, 0.8) !important;
}

.ui.vertical.menu.menu-sidebar .active.item {
    border-color: #DB2828!important;
    color: #DB2828!important;
}

.menu-item-current-user {
    text-align:center;
}

.menu-item-title {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 1.1em;
}

.menu-item-bottom, .ui.vertical.menu.menu-sidebar .menu-item-bottom {
    margin-top:auto;
}

.App-logo {
    width:100% !important;
}

@media (max-width: 1049px) {
    .menu-item-logo {
        text-align: center;
    }
    .App-logo {
        width: 75% !important;
    }
}